import { environment } from '@environment';

export const buildOutsystemsUrl = (urlPath = '/') => {
  if (!environment.outsystemsUrl) {
    return;
  }
  return `${environment.outsystemsUrl}${urlPath}`;
};

/* eslint-disable max-len */
export const externalUrls = {
  ucLibrary: 'https://libcat.canterbury.ac.nz/MyResearch/Home',
  timetable: 'https://timetable.canterbury.ac.nz/odd/student',
  ucHomepage: 'https://www.canterbury.ac.nz/',
  ucTeachingQualification:
    'https://www.canterbury.ac.nz/study/academic-study/education/study-education/education-qualifications/teacher-education/secondary-teaching',
  ucGuideToApplying:
    'https://www.canterbury.ac.nz/study/academic-study/education/study-education/education-qualifications/teacher-education/guide-to-applying-for-teacher-education',
  ucEmergency: 'https://www.canterbury.ac.nz/life/support-and-wellbeing/emergency-information',
  ucDocumentCertification:
    'https://www.canterbury.ac.nz/study/getting-started/admission-and-enrolment/enrolment-topics/identity-and-citizenship-verification',
  ucInternationalEnglish:
    'https://www.canterbury.ac.nz/study/getting-started/admission-and-enrolment/enrolment-topics/english-language-proficiency',
  ucAdmissionAndEnrolment: 'https://www.canterbury.ac.nz/study/getting-started/admission-and-enrolment',
  ucTransferOfCredit:
    'https://www.canterbury.ac.nz/study/getting-started/admission-and-enrolment/enrolment-topics/transfer-of-credit',
  ucExams: 'https://www.canterbury.ac.nz/study/study-support-info/study-related-topics/examinations',
  ucHealthCenter: 'https://www.canterbury.ac.nz/life/support-and-wellbeing/uc-health-centre',
  ucCopyright:
    'https://www.canterbury.ac.nz/about-uc/corporate-information/privacy-copyright-official-information/copyright',
  ucIntInsuranceHelp:
    'https://www.canterbury.ac.nz/study/getting-started/study-and-living-costs/insurance-for-international-students',
  ucStudentsafeInsurance:
    'https://www.canterbury.ac.nz/study/getting-started/study-and-living-costs/insurance-for-international-students/studentsafe-insurance',
  ucApprovedIntInsurers:
    'https://www.canterbury.ac.nz/study/getting-started/study-and-living-costs/insurance-for-international-students/alternative-insurance',
  ucGettingStarted: 'https://www.canterbury.ac.nz/study/getting-started',
  ucStudyCosts: 'https://www.canterbury.ac.nz/study/getting-started/study-and-living-costs/study-costs',
  ucLivingCosts: 'https://www.canterbury.ac.nz/study/getting-started/study-and-living-costs/living-costs',
  ucStudentServicesLevy:
    'https://www.canterbury.ac.nz/study/getting-started/study-and-living-costs/study-costs/student-services-levy',
  ucInternationalFees:
    'https://www.canterbury.ac.nz/study/getting-started/study-and-living-costs/study-costs/international-tuition-fees',
  ucScholarships: 'https://www.canterbury.ac.nz/study/getting-started/scholarships',
  ucIntInsuranceAssessment:
    'https://www.canterbury.ac.nz/content/dam/uoc-main-site/documents/pdfs/forms/international-student-insurance-policy-assessment-application-form-UC.pdf',
  ucPrivacyAndCopyright:
    'https://www.canterbury.ac.nz/about-uc/corporate-information/privacy-copyright-official-information',
  ucProfile: 'https://profiles.canterbury.ac.nz/',
  ucCodeOfConduct:
    'https://www.canterbury.ac.nz/about-uc/corporate-information/codes-of-practice/student-code-of-conduct',
  ucStudentServicesLevyPolicy:
    'https://www.canterbury.ac.nz/about-uc/corporate-information/policies/student-services-levy-policy',
  ucCookies:
    'https://www.canterbury.ac.nz/about-uc/corporate-information/privacy-copyright-official-information/cookies-declaration',

  agentPortal: 'https://connectuc.canterbury.ac.nz/',

  ucCareers: 'https://www.canterbury.ac.nz/life/jobs-and-careers',
  ucCWYN: 'https://checkwhatyouneed.canterbury.ac.nz/home',
  ucOneDrive: 'https://www.microsoft.com/en-nz/microsoft-365/onedrive/online-cloud-storage',
  ucAPlusStudent: 'https://learn.canterbury.ac.nz/',
  ucPasswordReset: 'https://password.canterbury.ac.nz/',
  ucITAccount: 'https://password.canterbury.ac.nz/',
  googleTerms: 'https://policies.google.com/terms',
  googlePriv: 'https://policies.google.com/privacy',
  countryCode: 'https://countrycode.org/',
  gmapsMeasureDistance: 'https://www.google.co.nz/maps',
  UCSA: 'https://ucsa.org.nz/',
  educationCodeOfPractice: 'https://www2.nzqa.govt.nz/tertiary/the-code/',
  askAQuestion:
    'https://www.canterbury.ac.nz/life/support-and-wellbeing/uc-support-services/international-relationships-office/ask-a-question',
  teachingcouncil: 'https://teachingcouncil.nz/',
  overSeasPoliceClearance:
    'https://teachingcouncil.nz/getting-certificated/for-overseas-trained-teachers/overseas-police-clearance/',
  nonBinaryGender: 'https://fyi.org.nz/request/3743/response/12261/attach/html/3/oia%20988129.pdf.html',
  studentEmailLogin: 'https://outlook.com/uclive.ac.nz',
  studyLink: 'https://www.studylink.govt.nz',
  ras: 'https://password.canterbury.ac.nz/',

  internationalToolkit:
    'https://www.canterbury.ac.nz/study/getting-started/getting-started-for-international-students/agent-toolkit',
  gpaRequirements:
    'https://www.canterbury.ac.nz/content/dam/uoc-main-site/documents/pdfs/d-other/Programme-specific-entry-requirements.pdf',
  programmeRelatedInformation: 'https://checkwhatyouneed.canterbury.ac.nz/home',
  postgraduateRequirements: 'https://checkwhatyouneed.canterbury.ac.nz/checkrequirements/postgraduate',
  englishLanguageRequirements:
    'https://www.canterbury.ac.nz/study/getting-started/admission-and-enrolment/enrolment-topics/english-language-proficiency',
  qualificationsAtUC: 'https://www.canterbury.ac.nz/study/getting-started/about-our-qualifications',
  star: 'https://www.canterbury.ac.nz/study/other-study-options/star-programme',
  extramuralStudyForm:
    'https://www.canterbury.ac.nz/content/dam/uoc-main-site/documents/word-docs/uc-graduate-school/Change-of-Study-Location-uc.docx',
  timetablePlanner: 'https://mytimetable.canterbury.ac.nz/aplus2021/timetable/#subjects',
  academicAdvisors: 'https://www.canterbury.ac.nz/study/study-support-info/study-support/student-advisors',
  schoolLiaisonContact: 'https://www.canterbury.ac.nz/study/getting-started/future-students-office',
  microCredentialConsent: 'https://www.canterbury.ac.nz/study/other-study-options/microcredentials-and-short-courses',
  help: 'https://ucliveac.sharepoint.com/sites/IntranetWHDigitalInfoServices/SitePages/Our-UC.aspx',
  costOfLiving:
    'https://www.canterbury.ac.nz/study/getting-started/study-and-living-costs/living-costs/living-costs-estimate-halls-of-residence',
  whatIsSpecialAboutUC: 'https://www.canterbury.ac.nz/about-uc/why-uc',
  studentAccessibilityService: 'https://www.canterbury.ac.nz/life/accessibility',
  ucKeyCourseDates:
    'https://www.canterbury.ac.nz/study/getting-started/admission-and-enrolment/enrolment-topics/enrolment-and-course-dates',
  SAMLProvider: 'https://scholarshipscanterbury.communityforce.com/SAML/AssertionConsumerService.aspx',

  // Relative

  UCSW: {
    CONTALL: '/ucsms/ee_login.aspx?PAGE=CONTALL',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    COEHOME: (year) => `/ucsms/ee_login.aspx?PAGE=STUCOE&YEAR=${year}`,
    ENROFF: '/ucsms/ee_login.aspx?PAGE=ENROFF',
    APPGRAD: '/ucsms/ee_login.aspx?page=APPGRAD',
    ENGPRAC: '/ucsms/ee_login.aspx?page=ENGPRAC',
    INTTRNS: '/ucsms/ee_login.aspx?page=INTTRNS',
    PAYDUE: '/ucsms/ee_login.aspx?page=PAYDUE',
    REFDUE: '/ucsms/ee_login.aspx?page=REFDUE',
    THESIS: '/ucsms/ee_login.aspx?page=THESIS',
    PAYPEND: '/ucsms/ee_login.aspx?page=PAYPEND',
    UCCARD: 'https://topup.canterbury.ac.nz/?from=myuc',
  },

  // Outsystems

  Outsystems: {
    dashboard: (errCode?) => {
      if (errCode) {
        return buildOutsystemsUrl(`/OurUC/Dashboard.aspx?Message=${errCode}`);
      } else {
        return buildOutsystemsUrl(`/OurUC/Dashboard.aspx`);
      }
    },
    studentManagement: (cid: string) => buildOutsystemsUrl(`/StudentManagement?CanonicalId=${cid}`),
    studentSearch: () => buildOutsystemsUrl(`/StudentSearch`),
    enrolments: (cid: string, year: string) =>
      buildOutsystemsUrl(
        `/StudentManagement/ApplicationSummary.aspx?CanonicalId=${cid}&IsFullyEnrolled=True&Year=${year}`,
      ),
    applications: (cid: string, year: string) =>
      buildOutsystemsUrl(
        `/StudentManagement/ApplicationSummary.aspx?CanonicalId=${cid}&IsFullyEnrolled=false&Year=${year}`,
      ),
    correspondence: (cid: string) =>
      buildOutsystemsUrl(`/StudentManagement/Documents.aspx?CanonicalId=${cid}&Doctype=correspondence`),
    evidentialDoc: (cid: string) =>
      buildOutsystemsUrl(`/StudentManagement/Documents.aspx?CanonicalId=${cid}&Doctype=supporting`),
    sanctions: (cid: string) => buildOutsystemsUrl(`/StudentManagement/Sanctions.aspx?CanonicalId=${cid}`),
    transcript: (cid: string) => buildOutsystemsUrl(`/StudentManagement/Transcript.aspx?CanonicalId=${cid}`),
  },

  gpaReport: 'https://support.scholaro.com/portal/en/kb/articles/canterbury',
  feedbackLink: (cid: string) => `http://canterbury.qualtrics.com/jfe/form/SV_9XiQeOblvPvIcqG?cid=${cid}`,
  myUCUrl: `https://myuc${environment.envName}.canterbury.ac.nz/`,
  ucOnlineHomepage: 'https://uconline.ac.nz',
  ucOnlineTotara: 'https://my.uconline.ac.nz',
};
